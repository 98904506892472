import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

type CreateReport = { data: any[]; columns: any[]; fileName: string };

export const createReport = (
  data: CreateReport,
): Promise<AxiosResponse<Blob>> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/report/generate-excel`,
    method: "POST",
    data,
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  return axios(config);
};
