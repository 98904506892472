//Dependancies
import { Download } from "@mui/icons-material";
import { Button } from "@mui/material";
import dayjs from "dayjs";

//Redux
import { useSelector } from "../../../redux/typedHooks";
import { createReport } from "../../../services/reports";

//Components
import { TabColums } from "./const";

const ExcelButton: React.FC = () => {
  const { reportData } = useSelector((store) => store.reportsReducer);
  async function createReportClick() {
    try {
      const response = await createReport({
        columns: TabColums,
        data: reportData as any,
        fileName: "test",
      });
      const currentDate = dayjs().format("MM_DD_YYYY");

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Users_Report_${currentDate}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.log({ error });
    }
  }

  return (
    <Button
      variant="contained"
      onClick={createReportClick}
      endIcon={<Download />}
    >
      Descargar
    </Button>
  );
};

export default ExcelButton;
