import dayjs from "dayjs";
import fileDownload from "js-file-download";

import {
  downloadFileApplication,
  downloadFileImage,
  showFileUrl,
} from "../../services/application";

import { displayToast } from "./displayToast";

export const handleDownloadFile = async (file: any) => {
  try {
    const { data } = await downloadFileApplication(file._id);
    fileDownload(data, `${file.name}`);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};

export const handleOpenDownloadFile = async (file: any) => {
  try {
    const { data } = await showFileUrl(file._id);

    // // Abre el archivo en una nueva pestaña
    const newWindow = window.open("", "_blank"); // Abre una nueva pestaña vacía

    if (newWindow) {
      // Cambiar el título de la nueva pestaña
      newWindow.document.title = "Deck";
      const embed = document.createElement("embed"); // Crea un elemento embed

      embed.src = data.fileUrl; // Asigna la URL del archivo al embed
      embed.type = "application/pdf"; // Especifica el tipo de contenido
      embed.style.width = "100%";
      embed.style.height = "100vh";

      // Añade el embed al cuerpo de la nueva ventana
      newWindow.document.body.appendChild(embed);
    } else {
      displayToast("error", "No se pudo abrir una nueva pestaña");
    }
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};

export const handleDownloadImage = async (file: any, extension: string) => {
  try {
    const { data } = await downloadFileImage(file._id, extension);
    fileDownload(data, `${file.name}`);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};

export const handleDownloadReports = async (file: any) => {
  dayjs.locale("es");
  const id = file.file;
  const fileName = `Reporte-${dayjs(file.date).format("LL")}.pdf`;
  try {
    const { data } = await downloadFileApplication(id);
    fileDownload(data, fileName);
  } catch (error) {
    displayToast("error", "Error al descargar archivo");
  }
};
