import {
  Apartment,
  Email,
  FolderOpen,
  Info,
  Language,
  LocationOn,
  Person,
  Phone,
  StackedLineChart,
  Videocam,
  Lightbulb,
  ShowChart,
  AssignmentInd,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FC } from "react";
import { UseFormReturn } from "react-hook-form";

import BigAvatar from "../../../../../components/BigAvatar/BigAvatar";
import { useSelector } from "../../../../../redux/typedHooks";
import { handleOpenDownloadFile } from "../../../../../utils/helpers/downloadFile";
import { translateStatusOpportunities } from "../../../../../utils/text/translates";
import { ERolesCode } from "../../../../routes/role-catalog";
import mainStyles from "../../../styles";
import { styles } from "../styles";
import { IMainCardForm } from "../types";
import { useNameUser } from "../useNameUser";

import BoardItem from "./BoardItem/BoardItem";
import ButtonReadMode from "./ButtonReadMode/ButtonReadMode";
import ItemProperty from "./ItemProperty/ItemProperty";
import ItemSideAvatar from "./ItemSideAvatar/ItemSideAvatar";
import LinkCustom from "./LinkCustom/LinkCustom";
import LinkDeckCustom from "./LinkCustom/LinkDeckCustom";

interface IMainCardReadMode {
  hook: UseFormReturn<IMainCardForm>;
  changeMode: () => void;
}

export const ReadMode: FC<IMainCardReadMode> = ({ changeMode, hook }) => {
  const breakpoint = useMediaQuery("(max-width: 850px)");
  const mobileButton = useMediaQuery("(max-width: 525px)");
  const { user } = useSelector((store) => store.loggedUser.loggedUser);

  const analystName = useNameUser(hook.watch("analistAssigned"));
  const partnerName = useNameUser(hook.watch("partnerAssigned"));

  const isAdmin = [
    ERolesCode.ADMINISTRATOR,
    ERolesCode.PARTNER,
    ERolesCode.SUPER_ADMIN,
    ERolesCode.ANALYST,
  ].includes(user.role.code);

  function handleOpenPDF() {
    if (hook.getValues("deck")) {
      handleOpenDownloadFile(hook.getValues("deck"));
    }
  }

  const exist1 = hook.watch("founders")?.some((i) => i.linkedIn);
  const exist2 = hook.watch("founders")?.some((i) => i.name);
  const exist3 = hook.watch("founders")?.some((i) => i.role);

  const website = hook.watch("website");

  const deckText = hook.watch("deck.name") ? (
    <LinkDeckCustom
      onClick={handleOpenPDF}
      value={hook.getValues("deck.name")}
    />
  ) : (
    "Información Pendiente"
  );
  const pitchText = hook.watch("pitch") ? (
    <LinkCustom isRedirect value={hook.getValues("pitch") ?? ""} />
  ) : (
    "Información Pendiente"
  );

  return (
    <>
      <Box display={"flex"} width="100%" flexWrap={"wrap"}>
        <Box
          paddingRight={mobileButton ? "0px" : "56px"}
          flex={mobileButton ? 1 : 0}
          paddingTop={mobileButton ? "24px" : "0px"}
        >
          {mobileButton && (
            <Stack direction="row" justifyContent="end">
              <ButtonReadMode
                isMobile
                xs={{}}
                hook={hook}
                changeMode={changeMode}
              />
            </Stack>
          )}
          <Stack alignItems="center" paddingTop={mobileButton ? "24px" : "0px"}>
            <BigAvatar
              fileMulter={hook.watch("logo") as any}
              letter={hook.watch("startupName")[0]}
            />
            <Stack width={"100%"} spacing={2} marginTop="16px">
              <ItemSideAvatar
                icon={
                  <ShowChart
                    fontSize="large"
                    sx={{ color: "#4A7C2F", width: "22px", marginRight: "4px" }}
                  />
                }
                title="Estatus:"
                name={translateStatusOpportunities(hook.watch("status"))}
              />
              <ItemSideAvatar
                icon={
                  <AssignmentInd
                    fontSize="large"
                    sx={{ color: "#4A7C2F", width: "22px", marginRight: "4px" }}
                  />
                }
                title="Asignado:"
                name={partnerName ? partnerName : analystName}
              />
            </Stack>
          </Stack>
        </Box>
        <Box flex={1} paddingTop={breakpoint ? "28px" : "0px"}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap={"wrap"}
            gap={1}
          >
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"center"}
              flexWrap={"wrap"}
              gap={1}
            >
              <Typography sx={styles.textCompany}>
                {hook?.watch("startupName")}
              </Typography>
              <Stack
                direction={"row"}
                alignItems="center"
                flexWrap={"wrap"}
                justifyContent={"center"}
                rowGap={1}
              >
                <Chip
                  sx={styles.industryChip}
                  size="small"
                  label={
                    <Typography sx={styles.chipText}>
                      {hook.watch("industry")}
                    </Typography>
                  }
                />
                {hook.watch("womanFounding") === "Si" && isAdmin && (
                  <Chip
                    sx={styles.womanChip}
                    size="small"
                    label={
                      <Typography sx={styles.chipText}>
                        Mujer en el founding team
                      </Typography>
                    }
                  />
                )}
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              sx={{
                marginTop: breakpoint ? "1rem" : "",
              }}
            >
              {!mobileButton && (
                <ButtonReadMode
                  xs={mainStyles.actionsButtons}
                  hook={hook}
                  changeMode={changeMode}
                />
              )}
            </Stack>
          </Stack>
          <Typography sx={styles.oneliner}>{hook.watch("oneliner")}</Typography>
          <Typography sx={styles.subtitle}>Acerca de la Startup</Typography>
          <Grid container>
            <Grid item xs={12} md={5}>
              <ItemProperty
                icon={<StackedLineChart sx={mainStyles.icon} />}
                label="Etapa de Inversión"
                text={hook.watch("stage")}
                doesHasSpace
              />
              <ItemProperty
                icon={<Apartment sx={mainStyles.icon} />}
                label="Tipo de Empresa"
                text={hook.watch("companyType")}
                doesHasSpace
              />
            </Grid>
            <Grid direction="column" item xs={12} md={6}>
              <ItemProperty
                icon={<Language sx={mainStyles.icon} />}
                label="Sitio Web"
                text={<LinkCustom isRedirect value={website} />}
                doesHasSpace
              />
              <ItemProperty
                icon={<LocationOn sx={mainStyles.icon} />}
                label="País"
                text={hook.watch("country")}
                doesHasSpace
              />
            </Grid>
          </Grid>
          {hook.watch("solvingProblem") && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <ItemProperty
                icon={<Info sx={mainStyles.icon} />}
                label="¿Qué problema resuelves?"
                text={hook.watch("solvingProblem")}
              />
            </Box>
          )}
          {hook.watch("uniqueValueProposal") && (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <ItemProperty
                icon={<Info sx={mainStyles.icon} />}
                label="¿Cuál es tu propuesta de valor?"
                text={hook.watch("uniqueValueProposal")}
              />
            </Box>
          )}
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <ItemProperty
              icon={<Lightbulb sx={mainStyles.icon} />}
              label="¿Cómo llegó a AngelHub?"
              text={hook.watch("howFindAngelHub")}
            />
          </Box>
          <Divider sx={styles.divider} />
          <Typography sx={styles.subtitle}>Contacto</Typography>
          <Grid container>
            <Grid item xs={12} md={5}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <ItemProperty
                  icon={<Person sx={mainStyles.icon} />}
                  label="Nombre"
                  doesHasSpace
                  text={`${hook.watch("ownerName")} ${hook.watch(
                    "ownerLastName",
                  )}`}
                />
              </Box>
              <ItemProperty
                icon={<Phone sx={mainStyles.icon} />}
                label="Teléfono"
                text={`+${hook.watch("phoneNumber")}`}
                doesHasSpace
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                <ItemProperty
                  icon={<Email sx={mainStyles.icon} />}
                  label="Email"
                  text={<LinkCustom value={hook.watch("email")} />}
                />
              </Box>
            </Grid>
          </Grid>
          {exist1 && exist2 && exist3 && (
            <>
              <Divider sx={styles.divider} />
              <Typography sx={styles.subtitle}>Equipo Fundador</Typography>
            </>
          )}
          <Grid container direction={mobileButton ? "column" : "row"}>
            {hook.watch("founders")?.map((founder, index) => (
              <Grid key={index} item xs={12} md={3} xl={2}>
                <BoardItem
                  role={founder.role}
                  name={founder.name}
                  linkedinUrl={founder.linkedIn}
                />
              </Grid>
            ))}
          </Grid>

          <Divider sx={styles.divider} />
          <Typography sx={styles.subtitle}>Deck y pitch</Typography>
          <Grid container>
            <Grid container direction="column" item xs={12} md={5}>
              <ItemProperty
                icon={<FolderOpen sx={mainStyles.icon} />}
                label="Deck"
                text={deckText}
                doesHasSpace
              />
            </Grid>
            <Grid container direction="column" item xs={12} md={6}>
              <ItemProperty
                icon={<Videocam sx={mainStyles.icon} />}
                label="Video Pitch"
                text={pitchText}
                doesHasSpace
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
