import React from "react";

import PageTitle from "../../components/DashboardLayout/PageTitle";
import MainLayout from "../../components/MainLayout";

import ReportList from "./ReportList";

const Report: React.FC = () => {
  return (
    <>
      <PageTitle title="Reportes" />
      <MainLayout sx={{ flexDirection: "column", justifyContent: "start" }}>
        <ReportList />
      </MainLayout>
    </>
  );
};

export default Report;
