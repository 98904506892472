import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

import { useLoading } from "../../../hooks/useLoading";
import { createApplicationStartup } from "../../../services/application";
import { displayToast } from "../../../utils/helpers/displayToast";
import RegisterStartupForm from "../RegisterStartupForm/RegisterStartupForm";
import { Screens } from "../StartupWizards";
import { preStartupSchema } from "../schema";

export interface IForm {
  startupName: string;
  oneliner: string;
  industry: string;
  stage: string;
  companyType: string;
  requiredInvestment: string;
  valuation: string;
  howFindAngelHub: string;
  uniqueValueProposal: string;
  solvingProblem: string;
  linkedInProfile: string;
  ownerName: string;
  ownerLastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  website: string;
  file: null | any;
  country: string;
  womanFounding: string;
}

interface IProps {
  changeOption: (value: Screens) => void;
}

const YesScreen: React.FC<IProps> = ({ changeOption }) => {
  const { handleClose, handleOpen } = useLoading();

  const [ValidRecaptcha, setValidRecaptcha] = useState<boolean>(false);
  const hook = useForm<IForm>({
    mode: "all",
    resolver: yupResolver(preStartupSchema),
    defaultValues: {
      startupName: "",
      oneliner: "",
      industry: "",
      stage: "",
      companyType: "",
      requiredInvestment: "",
      howFindAngelHub: "",
      uniqueValueProposal: "",
      solvingProblem: "",
      linkedInProfile: "",
      ownerName: "",
      ownerLastName: "",
      email: "",
      phoneNumber: "",
      website: "",
      file: null,
      phoneCountryCode: "",
      valuation: "",
      country: "",
      womanFounding: "",
    },
  });

  function handleSuccessCaptcha() {
    setValidRecaptcha(true);
    displayToast("success", "Captcha exitoso");
  }

  function handleFailCaptcha() {
    displayToast("error", "Ocurrió un error al validar el captcha");
  }

  async function onSubmit(values: IForm) {
    if (!ValidRecaptcha) {
      handleFailCaptcha();
      return;
    }
    const formdata = new FormData();
    formdata.append("startupName", values.startupName);
    formdata.append("oneliner", values.oneliner);
    formdata.append("industry", values.industry);
    formdata.append("stage", values.stage);
    formdata.append("companyType", values.companyType);
    formdata.append("requiredInvestment", values.requiredInvestment);
    formdata.append("postMoney", values.valuation);
    formdata.append("howFindAngelHub", values.howFindAngelHub);
    formdata.append("ownerName", values.ownerName);
    formdata.append("ownerLastName", values.ownerLastName);
    formdata.append("email", values.email);
    formdata.append("phoneCountryCode", values.phoneCountryCode);
    formdata.append(
      "phoneNumber",
      values.phoneNumber.replace(values.phoneCountryCode, ""),
    );
    formdata.append("website", values.website);
    formdata.append("country", values.country);
    formdata.append("womanFounding", values.womanFounding.toString());
    formdata.append("applicationDate", new Date() as any);
    formdata.append("files", values.file);
    formdata.append("linkedInProfile", values.linkedInProfile);
    formdata.append("uniqueValueProposal", values.uniqueValueProposal);
    formdata.append("solvingProblem", values.solvingProblem);

    try {
      handleOpen();
      await createApplicationStartup(formdata);
      changeOption("End");
      displayToast("success", "Se ha creado exitosamente la aplicación.");
    } catch (error: any) {
      const message = error?.data?.message;
      if (message === "Correo no valido") {
        message && hook.setError("email", { message: "Correo ya registrado" });
      } else if (message === "El CV debe tener formato PDF") {
        message && hook.setError("file", { message });
      } else if (
        message ===
        "Solo se puede aplicar una vez cada seis meses con el mismo correo."
      ) {
        hook.setError("email", {
          message: "Correo ya registrado",
        });
        displayToast(
          "error",
          "Lo siento, pero no es posible crear un registro con el mismo correo electrónico dentro del lapso de 6 meses.",
        );
      } else {
        displayToast("error", "Ocurrió un error");
      }
    } finally {
      handleClose();
    }
  }

  return (
    <Box
      sx={{
        maxWidth: "714px",
        border: "none",
      }}
    >
      <Typography sx={{ fontSize: "24px", color: "#495463" }}>
        Bienvenido a AngelHub
      </Typography>
      <Typography
        sx={{
          marginBottom: { xs: "30px", md: "50px" },
          marginTop: "24px",
          textAlign: "left",
          font: "normal normal normal 16px/19px Product Sans Medium",
          letterSpacing: "0px",
          color: "#5D5D5D",
        }}
      >
        Responde a las siguientes preguntas, recuerda ser lo más honesto y
        preciso posible con tus respuestas ya que esto nos ayuda a asignar de
        mejor manera tu proyecto.
      </Typography>
      <RegisterStartupForm
        hook={hook}
        handleSubmit={hook.handleSubmit(onSubmit)}
        handleCancel={() => changeOption("Start")}
        handleFailCaptcha={handleFailCaptcha}
        handleSuccessCaptcha={handleSuccessCaptcha}
      />
    </Box>
  );
};

export default YesScreen;
