import { GridColDef, GridColumnHeaderTitle } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";

import { CustomSort } from "../../components/DataGrid";
import Ellipsis from "../../utils/components/ellipsis";
import { translateRoles } from "../../utils/text/translates";
import { ERolesCode } from "../routes/role-catalog";

export const columnsAdmin = (column: any): GridColDef[] => {
  return [
    {
      headerName: "Nombres",
      field: "firstName",
      hideSortIcons: true,
      flex: 1,
      minWidth: 300,
      renderHeader: (params) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={colDef?.width ? colDef?.width : 200}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      renderCell(params) {
        const data = params.row;
        const userId = data._id;
        const startupId = data?.startup?._id;
        const memberId = data?.member?._id;
        const code = data.role?.code;

        const url = {
          [ERolesCode.ADMINISTRATOR]: `/update-admin/${userId}`,
          [ERolesCode.SUPER_ADMIN]: `/update-admin/${userId}`,
          [ERolesCode.ANALYST]: `/update-analyst/${userId}`,
          [ERolesCode.MEMBER]: `/update-member/${memberId}`,
          [ERolesCode.PARTNER]: `/update-partner/${userId}`,
          [ERolesCode.STARTUP]: `/update-startup/${startupId}`,
        } as any;

        return (
          <Ellipsis
            value={
              <NavLink style={{ color: "#4A88C2" }} to={url[code]}>
                {params.row.firstName + " " + params.row.lastName}
              </NavLink>
            }
          />
        );
      },
    },
    {
      headerName: "Correo",
      field: "email",
      hideSortIcons: true,
      minWidth: 350,
      renderHeader: (params) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={colDef?.width ? colDef?.width : 200}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Teléfono",
      field: "phoneNumber",
      hideSortIcons: true,
      minWidth: 180,
      renderHeader: (params) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={colDef?.width ? colDef?.width : 200}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
    },
    {
      headerName: "Fecha de creación",
      field: "createdAt",
      flex: 1,
      hideSortIcons: true,
      minWidth: 180,
      valueGetter: (params) => {
        return dayjs(params.row.createdAt).format("DD/MM/YYYY");
      },
    },
    {
      headerName: "Estado",
      field: "isActive",
      hideSortIcons: true,
      minWidth: 120,
      renderHeader: (params) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={colDef?.width ? colDef?.width : 200}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      valueGetter: (params) => {
        return params.row.isActive ? "Activo" : "Inactivo";
      },
    },
    {
      headerName: "Rol",
      field: "role",
      flex: 1,
      hideSortIcons: true,
      minWidth: 140,
      renderHeader: (params) => {
        const { colDef } = params;
        return (
          <>
            <GridColumnHeaderTitle
              label={colDef.headerName || colDef.field}
              description={colDef.description}
              columnWidth={colDef?.width ? colDef?.width : 200}
            />
            <CustomSort id={colDef.field} />
          </>
        );
      },
      valueGetter: (params) => {
        return translateRoles(params.row.role.name);
      },
    },
    column,
  ];
};
