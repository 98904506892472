//Dependencies
import { TabList } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

//Redux
import { resetReportData } from "../../../redux/actions/reports";
import { useDispatch } from "../../../redux/typedHooks";

//Constants
import { EReportTabs } from "./const";

const ReportTabs: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const stylesTabs = {
    color: "#0000005D",
    minWidth: "150px",
    minHeight: "48px",
  } as const;

  const handleChangeTab = (_: any, newTab: string) => {
    history.replace(`?tab=${newTab}`);
    dispatch(resetReportData());
  };

  return (
    <TabList
      sx={{ gap: "8px" }}
      scrollButtons
      variant="scrollable"
      allowScrollButtonsMobile
      onChange={handleChangeTab}
    >
      <Tab
        sx={stylesTabs}
        label="Start Up y miembros"
        value={EReportTabs.ALL}
      />
      <Tab sx={stylesTabs} label="MIEMBROS" value={EReportTabs.MEMBERS} />
    </TabList>
  );
};

export default ReportTabs;
