import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { getToken } from "../config";

export function applicationsPaginated(
  limit: number,
  page: number,
  query: any,
): Promise<any> {
  const token = getToken();

  let url = `/member-applications/data-grid?limit=${limit}&page=${page + 1}`;

  if (query.Status) {
    url += `&status=${query.Status}`;
  }
  if (query.Asigned) {
    url += `&partner=${query.Asigned}`;
  }
  if (query.Date) {
    url += `&date=${new Date()}&typeDate=${query.Date}`;
  }

  if (query.Nacionalty) {
    url += `&nationalityCountry=${query.Nacionalty}`;
  }
  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}

export function getApplications(): Promise<any> {
  const token = getToken();

  const url = `/member-applications/aplications`;

  const config: AxiosRequestConfig = {
    url,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
}
export function createApplicationStartup(data: any): Promise<any> {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: "/opportunities",
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  };
  return axios(config);
}

export const downloadFileApplication = (id: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/files/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };
  return axios(config);
};

export const downloadFileImage = (
  id: string,
  param: string,
): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/files/images/${id}/${param}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "blob",
  };
  return axios(config);
};

export const showFileUrl = (id: string): Promise<AxiosResponse> => {
  const token = getToken();
  const config: AxiosRequestConfig = {
    url: `/files/url/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: "json",
  };
  return axios(config);
};
