import * as yup from "yup";

import { CAMPO_REQUERIDO, CORREO_NO_VALIDO } from "../../utils/text/inputs";
import {
  LINKEDIN_URL_VALIDA,
  URL_VALIDA_FORM,
} from "../../utils/text/resetForm";
import { VALIDATE_LINKEDIN_PROFILE_REGEX } from "../MemberFaceliftOnboarding/FormRecapcha/schema";
export const VALIDATE_WEBSITE_REGEX = /^(http(s)?:\/\/)?(www\.)/gm;
export const preStartupSchema = yup.object({
  startupName: yup.string().required(CAMPO_REQUERIDO),
  oneliner: yup
    .string()
    .max(200, "Máximo de caracteres.")
    .required(CAMPO_REQUERIDO),
  industry: yup.string().required(CAMPO_REQUERIDO),
  stage: yup.string().required(CAMPO_REQUERIDO),
  companyType: yup.string().required(CAMPO_REQUERIDO),
  requiredInvestment: yup.string().required(CAMPO_REQUERIDO),
  valuation: yup.string().required(CAMPO_REQUERIDO),
  howFindAngelHub: yup.string().required(CAMPO_REQUERIDO),
  uniqueValueProposal: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .max(450, "Campo máximo de 450 caracteres."),
  solvingProblem: yup
    .string()
    .required(CAMPO_REQUERIDO)
    .max(450, "Campo máximo de 450 caracteres."),
  linkedInProfile: yup.string().matches(VALIDATE_LINKEDIN_PROFILE_REGEX, {
    message: LINKEDIN_URL_VALIDA,
    excludeEmptyString: true,
  }),
  ownerName: yup.string().required(CAMPO_REQUERIDO),
  ownerLastName: yup.string().required(CAMPO_REQUERIDO),
  email: yup.string().email(CORREO_NO_VALIDO).required(CAMPO_REQUERIDO),
  phoneCountryCode: yup.string().required(CAMPO_REQUERIDO),
  phoneNumber: yup.string().required(CAMPO_REQUERIDO),
  website: yup.string().matches(VALIDATE_WEBSITE_REGEX, URL_VALIDA_FORM),
  country: yup.string().required(CAMPO_REQUERIDO),
  womanFounding: yup.string().required(CAMPO_REQUERIDO),
  file: yup
    .mixed()
    .required(CAMPO_REQUERIDO)
    .nullable(false)
    .typeError(CAMPO_REQUERIDO),
});
