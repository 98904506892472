import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  TabContext,
} from "@mui/lab";
import {
  Autocomplete,
  Badge,
  Box,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import makeStyles from "@mui/styles/makeStyles";
import {
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
//import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { ReactElement, VFC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { setRangedDateFilter, setPeriod } from "../../../redux/actions/reports";
import { useDispatch } from "../../../redux/typedHooks";
import DrawerFilter from "../../DrawerFilter/DrawerFilter";
import { TextInput } from "../../Inputs";

import ExcelButton from "./ExcelButton";
import ReportTabs from "./Tabs";
import { EReportTabs, USER_ACTIVITIES } from "./const";
import styles from "./styles";

const useStyles = makeStyles({
  inputLabel: {
    transform: "translate(14px, -3px) scale(0.75) !important",
  },
});

interface IBaseToolbarProps {
  onSearchChange?: (value: string) => void;
  onIsActiveChange?: (value: string | null) => void;
  onSearchClear?: () => void;
  buttonAction?: () => void;
  hideButton?: boolean;
  hideIcons?: boolean;
  children: ReactElement;
  resetPage?: () => void;
  Tab: any;
}

const BaseToolbar: VFC<IBaseToolbarProps> = (props) => {
  const dispatch = useDispatch();
  const {
    onSearchChange,
    onSearchClear,
    onIsActiveChange,
    hideButton,
    hideIcons,
    resetPage,
    Tab,
  } = props;
  const classes = useStyles();
  const [search, setSearch] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [rangedDate, setRangedDate] = useState<DateRange<Dayjs>>([null, null]);
  const newStartDate = rangedDate[0]?.format("YYYY-MM-DD");
  const newEndDate = rangedDate[1]?.format("YYYY-MM-DD");
  const isMainTab = Tab === "ALL";
  useMemo(
    () =>
      dispatch(
        setRangedDateFilter({ startDate: newStartDate, endDate: newEndDate }),
      ),
    [dispatch, newEndDate, newStartDate],
  );
  const isMobile = useMediaQuery("(max-width:730px)");
  const breakpoint = useMediaQuery("(max-width: 1350px)");

  const [Status, setStatus] = useState<string>("");
  const [InputStatus, setInputStatus] = useState<string>("");

  const history = useHistory();

  const handleSearchChange = (event: string) => {
    setSearch(event);
    onSearchChange && onSearchChange(event);
  };

  const handleSearchClear = () => {
    setSearch("");
    onSearchClear && onSearchClear();
  };

  const handleFilter = () => {
    onIsActiveChange && onIsActiveChange(Status);
    setOpenDrawer(false);
  };

  const handleChangePeriod = (_: any, newValue: any) => {
    resetPage && resetPage();
    dispatch(setPeriod({ period: newValue }));
    let search = "";

    if (Tab) {
      search += `?tab=${Tab}`;
    }

    history.replace({
      pathname: `${history.location.pathname}`,
      search,
    });
  };

  const filter = (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: "Inicial", end: "Final" }}
    >
      <DateRangePicker
        value={rangedDate}
        onChange={(newValue) => {
          setRangedDate(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <Grid container justifyContent={"space-between"}>
            <Grid item xs={5}>
              <TextField
                {...startProps}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "48px",
                    maxWidth: "180px",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    bottom: "0px",
                  },
                }}
                InputLabelProps={{
                  style: { top: "-3.5px" },
                  classes: { shrink: classes.inputLabel },
                }}
              />
            </Grid>
            <Grid item xs={1.5}>
              <Box sx={{ textAlign: "center", paddingTop: "15px" }}>al</Box>
            </Grid>
            <Grid item xs={5.5}>
              <TextField
                {...endProps}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    height: "48px",
                    maxWidth: "180px",
                  },
                }}
                InputLabelProps={{
                  style: { top: "-3.5px" },
                  classes: { shrink: classes.inputLabel },
                }}
              />
            </Grid>
          </Grid>
        )}
      />
      <Autocomplete
        sx={breakpoint ? styles.searchField : styles.filterField}
        value={Status}
        inputValue={InputStatus}
        onInputChange={(_, newInputValue) => {
          setStatus(newInputValue);
          setInputStatus(newInputValue);
        }}
        onChange={handleChangePeriod}
        options={USER_ACTIVITIES}
        getOptionLabel={(option) => option}
        renderInput={({ InputLabelProps, InputProps, inputProps, ...rest }) => (
          <TextInput
            {...rest}
            InputProps={{
              ...InputProps,
              style: { height: 48, paddingTop: "6px" },
            }}
            inputProps={inputProps}
            InputLabelProps={{
              ...InputLabelProps,
              style: { top: "-3.5px" },
              classes: { shrink: classes.inputLabel },
            }}
            label="Actividad de usuario"
          />
        )}
      />
    </LocalizationProvider>
  );

  return (
    <TabContext value={Tab}>
      <Grid container sx={{ ...styles.toolbar, gap: "12px" }}>
        <Grid container item xs={12} spacing={2}>
          <Box
            sx={{
              padding: "15px 0px 25px 0px",
              width: "100%",
              overflow: "hide",
            }}
          >
            <ReportTabs />
          </Box>
        </Grid>
        <Grid container item xs={12} sx={{ gap: "12px" }}>
          <TextInput
            value={search}
            fullWidth={isMobile}
            onChange={(e) => {
              handleSearchChange(String(e.target.value));
            }}
            label="Buscar"
            InputProps={{
              sx: { height: "48px" },
              startAdornment: (
                <SearchIcon fontSize="small" sx={styles.searchIcon} />
              ),
              endAdornment: (
                <IconButton
                  title="Clear"
                  aria-label="Clear"
                  size="small"
                  style={{
                    visibility: search.length > 0 ? "visible" : "hidden",
                  }}
                  onClick={handleSearchClear}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              ),
            }}
          />

          <Box
            sx={
              isMainTab ? styles.optionsWrapper : styles.optionsWrapperSecundary
            }
          >
            {breakpoint ? (
              <IconButton onClick={() => setOpenDrawer(true)}>
                <Badge badgeContent={Status ? 1 : 0} color="primary">
                  <FilterListIcon />
                </Badge>
              </IconButton>
            ) : (
              <Box sx={isMainTab ? styles.mainTab : styles.secondaryTab}>
                {filter}
              </Box>
            )}
            <DrawerFilter
              isOpen={openDrawer}
              onFilter={handleFilter}
              onClose={() => setOpenDrawer(false)}
            >
              {Tab === EReportTabs.ALL && filter}
            </DrawerFilter>
            <Box display={"flex"} alignItems="center">
              {!hideIcons && (
                <Box sx={styles.iconsWrapper}>
                  <GridToolbarDensitySelector
                    {...({} as any)}
                    sx={styles.icon}
                  />
                  <GridToolbarExport {...({} as any)} sx={styles.icon} />
                </Box>
              )}

              {!hideButton && <ExcelButton />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </TabContext>
  );
};
export default BaseToolbar;
