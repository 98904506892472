import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import BaseDataGrid from "../../components/BaseDataGrid/BaseDataGrid";
import { ModalMenu } from "../../components/DataGrid";
import { DEFAULT_ROWS_PAGE_SIZE } from "../../components/DataGrid/constants";
import ReportsToolbar from "../../components/Toolbars/ReportsToolbar";
import {
  EReportTabs,
  UserActivitiesType,
} from "../../components/Toolbars/ReportsToolbar/const";
import { IUser } from "../../models/User";
import { fetchUsers } from "../../redux/actions/admin";
import { setReportData } from "../../redux/actions/reports";
import { useDispatch, useSelector } from "../../redux/typedHooks";
import { ERolesCode } from "../routes/role-catalog";

import { columnsAdmin } from "./columns";

const ReportList: React.FC = () => {
  const { users, loading, reload } = useSelector((store) => store.adminReducer);
  const { startDate, endDate, period, reportData } = useSelector(
    (store) => store.reportsReducer,
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [Search, setSearch] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_ROWS_PAGE_SIZE);
  const [Role, setRoles] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean | null>(null);

  const Tab = queryParams.get("tab") || EReportTabs.ALL; //
  const Period: UserActivitiesType =
    (queryParams.get("period") as UserActivitiesType) || ""; //
  const existDate = !startDate || !endDate;
  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch, reload]);

  useEffect(() => {
    const handleFilter = () => {
      let values = users.filter((user: IUser) => {
        return (
          String(user.firstName)
            ?.toLowerCase()
            .includes(Search.toLowerCase()) ||
          String(user.lastName)?.toLowerCase().includes(Search.toLowerCase()) ||
          String(user.email)?.toLowerCase().includes(Search.toLowerCase()) ||
          !Search
        );
      });

      if (Tab === EReportTabs.ALL) {
        values = values.filter(
          (i: any) =>
            i.role.code === EReportTabs.MEMBERS ||
            i.role.code === EReportTabs.STARTUPS,
        );
      }

      if (Tab === EReportTabs.MEMBERS) {
        values = values.filter((i: any) => i.role.code === EReportTabs.MEMBERS);
      }

      if (period && Tab !== EReportTabs.MEMBERS) {
        if (existDate) {
          return values;
        }

        const start = dayjs(startDate, "YYYY-MM-DD");
        const end = dayjs(endDate, "YYYY-MM-DD");

        if (period === "Fecha de registro") {
          values = values.filter((i: any) => {
            const itemDate = dayjs(i.createdAt);
            return (
              itemDate.isAfter(start.subtract(1, "day")) &&
              itemDate.isBefore(end.add(1, "day"))
            );
          });
        }
        if (period === "Ambos") {
          values = values.filter((i: any) => {
            const itemDate = dayjs(i.createdAt);
            return (
              itemDate.isAfter(start.subtract(1, "day")) &&
              itemDate.isBefore(end.add(1, "day"))
            );
          });
        }
      }
      dispatch(setReportData({ reportData: values }));
      return values;
    };
    handleFilter();
  }, [Search, Tab, dispatch, endDate, existDate, period, startDate, users]);

  useEffect(() => {
    setPage(0);
    setPageSize(DEFAULT_ROWS_PAGE_SIZE);
  }, [Role, isActive]);

  const renderModalMenu = (data: any) => {
    const code = data.role?.code;

    //Ids
    const userId = data._id;
    const startupId = data?.startup?._id;
    const memberId = data?.member?._id;

    const url = {
      [ERolesCode.ADMINISTRATOR]: `/update-admin/${userId}`,
      [ERolesCode.SUPER_ADMIN]: `/update-admin/${userId}`,
      [ERolesCode.ANALYST]: `/update-analyst/${userId}`,
      [ERolesCode.MEMBER]: `/update-member/${memberId}`,
      [ERolesCode.PARTNER]: `/update-partner/${userId}`,
      [ERolesCode.STARTUP]: `/update-startup/${startupId}`,
    } as any;

    const detailsOptionsList = [
      {
        name: "Ver",
        action: () => history.push(url[code]),
      },
    ];

    return <ModalMenu menuVert items={detailsOptionsList} />;
  };

  const customColumn: GridColDef = {
    headerName: "Acciones",
    field: "name",
    align: "center",
    hideSortIcons: true,
    renderCell: (data) => renderModalMenu(data.row),
  };

  return (
    <BaseDataGrid
      page={page}
      pageSize={pageSize}
      onPageChange={(value) => setPage(value)}
      onPageSizeChange={(value) => setPageSize(value)}
      columns={columnsAdmin(customColumn)}
      rows={reportData as any}
      loading={loading}
      getRowId={(row) => row._id}
      checkboxSelection
      components={{
        Toolbar: ReportsToolbar,
      }}
      componentsProps={{
        toolbar: {
          Tab,
          Period,
          onSearchChange: (value: string) => {
            setSearch(value);
          },
          onIsActiveChange: (value: string) => {
            if (value === null) {
              setIsActive(null);
            } else {
              setIsActive(value === "Activo");
            }
          },
          onTabChange: (value: string) => {
            setRoles(value);
          },
          onSearchClear: () => {
            setSearch("");
          },
          hideButton: false,
          hideIcons: true,
        },
      }}
    />
  );
};

export default ReportList;
