import { Link } from "@mui/material";
import React from "react";

import { CleanUrlString } from "../../../../../DetailsStartup/components/MainCard/utils";
import { styles } from "../../styles";

interface Props {
  value?: string;
  isRedirect?: boolean;
  onClick?: () => void;
}

const isPdf = (url?: string): boolean => {
  if (!url) return false;
  return url.toLowerCase().endsWith(".pdf");
};

const formatUrlForHref = (url?: string): string | undefined => {
  if (!url) return undefined;
  return url.startsWith("http://") || url.startsWith("https://")
    ? url
    : `https://${url}`;
};

const LinkDeckCustom: React.FC<Props> = ({ value, isRedirect, onClick }) => {
  const href = isRedirect ? formatUrlForHref(value) : undefined;
  return (
    <Link
      sx={{ ...styles.linkItemProperty }}
      underline={"hover"}
      target={isPdf(value) ? "_blank" : "_self"} // Abre PDFs en nueva pestaña
      rel={isPdf(value) ? "noopener noreferrer" : undefined} // Buena práctica para PDFs
      href={href}
      onClick={onClick}
    >
      {CleanUrlString(value || "No existe")}
    </Link>
  );
};

export default LinkDeckCustom;
