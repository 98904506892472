import {
  GET_RANGED_DATE,
  GET_PERIOD_FIELD,
  GET_REPORT_DATA,
  RESET_REPORT_DATA,
  RESET_FILTER,
} from "../actions/reports";

export type Report = {
  startDate: string | undefined;
  endDate: string | undefined;
  period: string | undefined;
  reportData: any[] | undefined;
};

export type RangedDates = Omit<Report, "period" | "reportData">;
export type Period = Pick<Report, "period">;
export type ReportData = Pick<Report, "reportData">;
export type ResetFilter = Omit<Report, "reportData">;

const initialState: Report = {
  startDate: "",
  endDate: "",
  period: "",
  reportData: [],
};

const resetFilter: ResetFilter = {
  startDate: "",
  endDate: "",
  period: "",
};

interface IReportDatesAction {
  type: typeof GET_RANGED_DATE;
  payload: RangedDates;
}

interface IReportPeriodAction {
  type: typeof GET_PERIOD_FIELD;
  payload: Period;
}

interface IReportDataAction {
  type: typeof GET_REPORT_DATA;
  payload: ReportData;
}

interface IResetReportDataAction {
  type: typeof RESET_REPORT_DATA;
}

interface IResetFilterAction {
  type: typeof RESET_FILTER;
}
type Action =
  | IReportDatesAction
  | IReportPeriodAction
  | IReportDataAction
  | IResetReportDataAction
  | IResetFilterAction;

export const ReportsReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case GET_RANGED_DATE:
      return {
        ...state,
        ...action.payload,
      };
    case GET_PERIOD_FIELD:
      return {
        ...state,
        ...action.payload,
      };
    case GET_REPORT_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_REPORT_DATA:
      return {
        ...initialState,
      };
    case RESET_FILTER:
      return {
        ...resetFilter,
      };
    default:
      return state;
  }
};
