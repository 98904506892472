export enum EReportTabs {
  ALL = "ALL",
  STARTUPS = "RL003",
  MEMBERS = "RL004",
}
export type PeriodType = "DIA" | "MES" | "TRIMESTRE" | "AÑO" | "";
export type UserActivitiesType =
  | "FECHA_DE_REGISTRO"
  | "ULTIMO_ACCESO"
  | "AMBOS"
  | "";
export enum EReports {
  FECHA_DE_REGISTRO = "FECHA_DE_REGISTRO",
  ULTIMO_ACCESO = "ULTIMO_ACCESO",
  AMBOS = "AMBOS",
}

export const USER_ACTIVITIES = ["Fecha de registro", "Último acceso", "Ambos"];
export const PERIOD_VALUES = ["DIA", "MES", "TRIMESTRE", "AÑO"];

export const TabColums = [
  {
    headerName: "Nombres",
    field: "firstName",
  },
  {
    headerName: "Correo",
    field: "email",
  },
  {
    headerName: "Telefono",
    field: "phoneNumber",
  },
  {
    headerName: "Fecha de creación",
    field: "createdAt",
  },
  {
    headerName: "Estado",
    field: "isActive",
  },
  {
    headerName: "Rol",
    field: "role",
  },
];
