import { Dispatch } from "redux";

import { RangedDates, Period, ReportData } from "../reducers/report";

export const GET_RANGED_DATE = "GET_RANGED_DATE";
export const GET_PERIOD_FIELD = "GET_PERIOD_FIELD";
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const RESET_REPORT_DATA = "RESET_REPORT_DATA";
export const RESET_FILTER = "RESET_FILTER";

export const setRangedDateFilter =
  (payload: RangedDates) => (dispatch: Dispatch) => {
    return dispatch({
      type: GET_RANGED_DATE,
      payload,
    });
  };

export const setPeriod = (payload: Period) => (dispatch: Dispatch) => {
  return dispatch({
    type: GET_PERIOD_FIELD,
    payload,
  });
};

export const setReportData = (payload: ReportData) => (dispatch: Dispatch) => {
  return dispatch({
    type: GET_REPORT_DATA,
    payload,
  });
};

export const resetReportData = () => (dispatch: Dispatch) => {
  return dispatch({
    type: RESET_REPORT_DATA,
  });
};

export const resetFilter = () => (dispatch: Dispatch) => {
  return dispatch({
    type: RESET_FILTER,
  });
};
